<template>
  <div>
    <b-sidebar
      id="sidebar-new-franchise"
      sidebar-class="sidebar-lg"
      :visible="newFranchiseSidebar.visible"
      bg-variant="white"
      shadow
      backdrop
      no-header
      right
      @shown="onShow"
      @change="(val) => mutateNewFranchiseSidebar({ visible: val })"
      @hidden="clear"
    >
      <template #default="{ hide }">
        <!-- Header -->
        <div
          class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"
        >
          <h4 v-if="newFranchiseSidebar.edit">Editar franquia</h4>
          <h4 v-if="newFranchiseSidebar.visualizar">Visualizar franquia</h4>
          <h4
            v-if="!newFranchiseSidebar.visualizar && !newFranchiseSidebar.edit"
          >
            Nova franquia
          </h4>
          <div>
            <feather-icon
              class="ml-1 cursor-pointer"
              icon="XIcon"
              size="16"
              @click="hide"
            />
          </div>
        </div>
        <div class="m-2" v-if="newFranchiseSidebar.visualizar">
          <b-row>
            <b-col cols="4" class="mb-1"> Nome: </b-col>
            <b-col cols="8" class="mb-1"> {{ newFranchiseSidebar.name }}</b-col>

            <b-col cols="4" class="mb-1"> Visível no Faturômetro e BI: </b-col>
            <b-col cols="8" class="mb-1">
              {{ newFranchiseSidebar.franchise_visible ? "Sim" : "Não" }}
            </b-col>

            <b-col cols="4" class="mb-1"> Responsáveis: </b-col>
            <b-col cols="8" class="mb-1" style="overflow-wrap: break-word">
              {{ newFranchiseSidebar.responsable }}
            </b-col>
          </b-row>
        </div>
        <!-- Form -->
        <b-form
          v-if="!newFranchiseSidebar.visualizar"
          class="p-2"
          @submit.prevent="onSubmit"
          @reset.prevent="resetForm"
        >
          <b-row>
            <b-col cols="12">
              <b-form-group label="Nome" label-for="lead-name">
                <b-form-input
                  id="lead-name"
                  v-model="name"
                  :class="{ 'is-invalid': v$.name.$error }"
                />
                <div class="invalid-feedback">
                  <span v-if="v$.name.$invalid">
                    Você deve informar o nome da franquia
                  </span>
                </div>
              </b-form-group>
            </b-col>
          </b-row>
          <b-form-checkbox
            class="my-1"
            v-model="franchise_visible"
            switch
            inline
          >
            Visível no Faturômetro e BI
          </b-form-checkbox>
          <b-row>
            <b-col cols="12" class="mt-1">
              <b-form-group label="Responsáveis" label-for="pn-responsable">
                <v-select
                  id="pn-responsable"
                  v-model="responsable"
                  :options="responsables"
                  :loading="loading.responsables"
                  label="name"
                  :class="getSelectErrorClass(v$.responsable.$error)"
                >
                  <template #no-options="{ search, searching, loading }">
                    Sem resultados
                  </template>
                  <div class="invalid-feedback">
                    <span v-if="v$.responsable.required.$invalid">
                      Você deve selecionar pelo menos um responsável
                    </span>
                  </div>
                </v-select>
                <!-- <all-select
                  id="pnResponsable"
                  v-model="responsable"
                  :options="responsables"
                  label="name"
                  take-this="id"
                  track-by="id"
                  :loading="loading.responsables"
                ></all-select> -->
              </b-form-group>
            </b-col>
          </b-row>

          <!-- Form Actions -->
          <div class="d-flex mt-2 justify-content-between">
            <b-button
              :disabled="saving"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="outline-primary"
              @click="hide"
              class="lead-buttons"
            >
              Voltar
            </b-button>
            <b-button
              v-if="newFranchiseSidebar.edit"
              :disabled="saving"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              type="submit"
              class="lead-buttons"
            >
              {{ saving ? "Salvando..." : "Editar" }}
            </b-button>
            <b-button
              v-else
              :disabled="saving"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              type="submit"
              class="lead-buttons"
            >
              {{ saving ? "Salvando..." : "Salvar" }}
            </b-button>
          </div>
        </b-form>
      </template>
    </b-sidebar>
  </div>
</template>

<script>
import {
  BButton,
  BSidebar,
  BForm,
  BFormGroup,
  BFormTextarea,
  BFormInput,
  BFormFile,
  BCol,
  BRow,
  BFormCheckbox,
} from "bootstrap-vue";
import { mapActions, mapMutations, mapGetters } from "vuex";
import vSelect from "vue-select";
import Ripple from "vue-ripple-directive";
import useVuelidate from "@vuelidate/core";
import { required, requiredIf } from "@vuelidate/validators";
import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent";
import DynamicSelect from "@/modules/shared/components/DynamicSelect";
import FileChooser from "@/modules/shared/components/FileChooser";
import * as types from "../store/types";
import { getVueSelectErrorClass } from "@/helpers/validators";
import AllSelect from "@/modules/shared/components/AllSelect";

export default {
  components: {
    // BSV
    BButton,
    BSidebar,
    BForm,
    BFormGroup,
    BFormTextarea,
    BFormInput,
    BFormFile,
    BCol,
    BRow,
    vSelect,
    DynamicSelect,
    FileChooser,
    BFormCheckbox,
    AllSelect,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      loading: {
        responsables: false,
      },
      name: "",
      franchise_visible: true,
      responsable: null,
      consultant_id: null,
      saving: false,
    };
  },
  setup() {
    return { toast: useToast(), v$: useVuelidate() };
  },
  validations() {
    return {
      name: { required },
      responsable: { required },
    };
  },
  computed: {
    ...mapGetters({
      newFranchiseSidebar: types.NEW_FRANCHISE_SIDEBAR,
      responsables: types.RESPONSABLES,
    }),
  },
  methods: {
    ...mapMutations({
      mutateNewFranchiseSidebar: types.MUTATE_NEW_FRANCHISE_SIDEBAR,
    }),
    ...mapActions({
      getFranchise: types.GET_DIGITAL_FRANCHISE,
      getResponsables: types.GET_RESPONSABLES,
      storeFranchise: types.STORE_FRANCHISE,
      editFranchise: types.EDIT_FRANCHISE,
    }),
    onShow() {
      this.loading.responsables = true;
      this.getResponsables()
        .catch(() => {
          this.toast({
            component: ToastificationContent,
            props: {
              title: "Oops!",
              text: "Ocorreu um erro ao carregar os objetivos para seleção. Entre em contato com o setor de TI.",
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
        })
        .finally(() => {
          this.loading.responsables = false;
        });

      if (this.newFranchiseSidebar.id) {
        this.getFranchise({ id: this.newFranchiseSidebar.id })
          .then((resp) => {
            var data = resp.data;
            if (data) {
              this.name = data.name;
              this.responsable = data.digital_franchise_owners.map(
                (item) => item.appify_consultant
              );
              this.franchise_visible = data.visible;
              this.v$.$touch();
            }
          })
          .catch(() => {
            this.toast({
              component: ToastificationContent,
              props: {
                title: "Oops!",
                text: "Ocorreu um erro ao carregar o lead para edição. Entre em contato com o setor de TI.",
                icon: "AlertTriangleIcon",
                variant: "danger",
              },
            });
          });
      }
    },

    async onSubmit() {
      const isFormCorrect = await this.v$.$validate();
      if (!isFormCorrect) return;
      this.saving = true;

      const { name, responsable, consultant_id } = this;

      const payload = {
        id: this.newFranchiseSidebar?.id,
        name,
        visible: this.franchise_visible ? 1 : 0,
        consultant_id: responsable.id,
      };

      if (this.newFranchiseSidebar.id) {
        this.editFranchise(payload)
          .then((response) => {
            // Handle success
            this.toast({
              component: ToastificationContent,
              props: {
                title: "Sucesso",
                text: "Franquia editada com sucesso!",
                icon: "CoffeeIcon",
                variant: "success",
              },
            });
            this.mutateNewFranchiseSidebar({ visible: false });
            this.newFranchiseSidebar.saveAction();
          })
          .catch(() => {
            // Handle error
            this.toast({
              component: ToastificationContent,
              props: {
                title: "Oops!",
                text: "Ocorreu um erro ao editar a franquia. Entre em contato com o setor de TI.",
                icon: "AlertTriangleIcon",
                variant: "danger",
              },
            });
          })
          .finally(() => {
            this.clear();
            this.newFranchiseSidebar.edit = false;
            this.saving = false;
          });
      } else {
        this.storeFranchise(payload)
          .then((response) => {
            // Handle success
            this.toast({
              component: ToastificationContent,
              props: {
                title: "Sucesso",
                text: "Franquia salva com sucesso!",
                icon: "CoffeeIcon",
                variant: "success",
              },
            });
            this.mutateNewFranchiseSidebar({ visible: false });
            this.newFranchiseSidebar.saveAction();
          })
          .catch((error) => {
            // Handle error
            this.toast({
              component: ToastificationContent,
              props: {
                title: "Oops!",
                text: `Ocorreu um erro ao salvar a franquia. Entre em contato com o setor de TI.`,
                icon: "AlertTriangleIcon",
                variant: "danger",
              },
            });
          })
          .finally(() => {
            this.clear();
            this.saving = false;
          });
      }
    },

    getSelectErrorClass(thereIsError) {
      return getVueSelectErrorClass(thereIsError);
    },

    clear() {
      this.name = "";
      this.franchise_visible = true;
      this.responsable = null;
      this.v$.$reset();
    },
  },
};
</script>

<style lang="scss" scoped>
.vue-tel-input {
  padding: 0.438rem 1rem;
}
</style>
<style lang="scss">
.sidebar-xg {
  width: 36rem;
}

.lead-buttons {
  width: 10rem;
}

.custom-file-input:lang(en) ~ .custom-file-label::after {
  content: "Buscar";
}

.resume-download-col {
  display: flex;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 20px;
}
</style>
